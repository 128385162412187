<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Blok">
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start" md="4">
            <v-select
              v-model="kavling_id"
              :options="kavlingOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(_id) => _id._id"
              placeholder="Pilih Kavling"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="5"> </b-col>
          <!-- spasi -->
          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-2 mb-2" align="end">
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cari Nama Blok"
              @input="changeStatus()"
            />
          </b-col>
          <!-- <b-col md="2">
            <v-select
              v-model="kavling_id"
              :options="kavlingOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(_id) => _id._id"
              placeholder="Pilih Kavling"
              @input="changeStatus()"
            />
          </b-col> -->
          <b-col md="2">
            <v-select
              v-model="payment_status"
              :options="paymentStatusBlockOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              placeholder="Pilih Status"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="buyer_name"
              placeholder="Cari Pembeli"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <!-- <b-col align="end" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah</span>
            </b-button>
          </b-col> -->
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'payment_status'">
              <span v-if="props.row.payment_status === 'LUNAS'">
                <b-badge variant="success"> LUNAS </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'TEMPO'">
                <b-badge variant="warning"> TEMPO </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'BOOKING'">
                <b-badge variant="info"> BOOKING </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'TERSEDIA'">
                <b-badge variant="primary"> TERSEDIA </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'length'">
              {{ props.row.length }} x {{ props.row.width }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <span v-else-if="props.column.field === 'price'">
              Rp.
              {{
                props.row.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.v-primary
                title="Detail"
                class="btn-icon mr-2"
                @click="showModalDetail(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="showModalUbah(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteInfo(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal form Form Blok-->
        <b-modal
          id="modal-detail-blok"
          ref="ref-form-data-blok"
          title="Form Data Blok"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="6" v-if="this.aksi === `ubahData`">
                  <b-form-group label="Tanggal Pembelian" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="formDisabled.purchase_date"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="this.aksi === `ubahData`">
                  <b-form-group label="Nama Pembeli" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="formDisabled.buyer_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6" v-if="this.aksi === `ubahData`">
                  <b-form-group label="Status Pembayaran" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="formDisabled.payment_status"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="this.aksi === `ubahData`">
                  <b-form-group label="Nama Kavling" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="formDisabled.kavling_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="this.aksi === `tambahData`">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Nama Kavling <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <v-select
                        v-model="formSubmit.kavling_id"
                        :options="kavlingOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :reduce="(_id) => _id._id"
                        placeholder="Pilih Kavling"
                        @input="pilihKavling($event)"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Nama Kavling Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label-for="name">
                    <template v-slot:label>
                      Nama Blok <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Blok"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="formSubmit.name"
                        placeholder="Masukkan Nama Blok"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Nama Blok Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="price"
                    v-if="
                      this.aksi === `tambahData` ||
                      (this.aksi === `ubahData` &&
                        this.formDisabled.payment_status != `LUNASS`)
                    "
                  >
                    <template v-slot:label>
                      Harga <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Harga"
                      rules="required"
                    >
                      <b-form-input
                        id="price"
                        v-model="formSubmit.price"
                        placeholder="Masukkan Harga"
                        @input="handleInputHarga"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Harga Wajib Diisi" : ""
                      }}</small>

                      <small v-if="formSubmit.price"> Rp. {{ price2 }} </small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label-for="price"
                    v-if="
                      this.aksi === `ubahData` &&
                      this.formDisabled.payment_status === `LUNASS`
                    "
                  >
                    <template v-slot:label> Harga </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Harga"
                      rules="required"
                    >
                      <b-form-input
                        id="price"
                        v-model="formSubmit.price"
                        placeholder="Masukkan Harga"
                        disabled
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Harga Wajib Diisi" : ""
                      }}</small>

                      <small v-if="formSubmit.price"> Rp. {{ price2 }} </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label-for="length">
                    <template v-slot:label>
                      Luas Tanah (Panjang) <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Luas Tanah (Panjang)"
                      rules="required"
                    >
                      <b-form-input
                        id="length"
                        v-model="formSubmit.length"
                        placeholder="Masukkan Panjang"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Luas Tanah (Panjang) Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Luas Tanah (Lebar)" label-for="width">
                    <template v-slot:label>
                      Luas Tanah (Lebar) <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Luas Tanah (Lebar)"
                      rules="required"
                    >
                      <b-form-input
                        id="width"
                        v-model="formSubmit.width"
                        placeholder="Masukkan Lebar"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "Luas Tanah (Lebar) Wajib Diisi" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                  class="mb-1"
                  v-if="
                    this.aksi === `tambahData` ||
                    (this.aksi === `ubahData` &&
                      this.formDisabled.payment_status != `LUNAS`)
                  "
                >
                  <b-form-checkbox v-model="formSubmit.is_booked">
                    Sudah di booking
                  </b-form-checkbox>
                </b-col>

                <b-col
                  md="12"
                  class="mb-1"
                  v-if="
                    this.aksi === `ubahData` &&
                    this.formDisabled.payment_status === `LUNAS`
                  "
                >
                  <b-form-checkbox v-model="formSubmit.is_booked" disabled>
                    Sudah di booking
                  </b-form-checkbox>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Catatan" label-for="mc-country">
                    <b-form-textarea
                      rows="3"
                      v-model="formSubmit.note"
                      placeholder="Masukkan Catatan"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

            <b-row class="mt-5 mb-2">
              <b-col align="end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="kembaliModalForm()"
                  class="mr-2"
                >
                  Batal
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="submitForm()"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
        <!-- end modal form form kavling -->

        <!-- modal form detail Blok-->
        <b-modal
          id="modal-detail-blok"
          ref="ref-detail-data-blok"
          title="Form Data Blok"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Tanggal Pembelian" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formDisabled.purchase_date"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Nama Pembeli" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formDisabled.buyer_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Status Pembayaran" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formDisabled.payment_status"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Nama Kavling" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formDisabled.kavling_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Nama Blok" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formSubmit.name"
                    placeholder="Masukkan Nama Blok"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Harga" label-for="price">
                  <b-form-input
                    id="price"
                    v-model="formSubmit.price"
                    placeholder="Masukkan Harga"
                    disabled
                  />

                  <small v-if="formSubmit.price"> Rp. {{ price2 }} </small>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Luas Tanah (Panjang)" label-for="length">
                  <b-form-input
                    id="length"
                    v-model="formSubmit.length"
                    placeholder="Masukkan Panjang"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Luas Tanah (Lebar)" label-for="width">
                  <b-form-input
                    id="width"
                    v-model="formSubmit.width"
                    placeholder="Masukkan Lebar"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-checkbox v-model="formSubmit.is_booked" disabled>
                  Sudah di booking
                </b-form-checkbox>
              </b-col>

              <b-col md="12">
                <b-form-group label="Catatan" label-for="mc-country">
                  <b-form-textarea
                    rows="3"
                    v-model="formSubmit.note"
                    placeholder="Masukkan Catatan"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-row class="mt-5 mb-2">
            <b-col align="end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="kembaliModalDetail()"
                class="mr-2"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal form detail kavling -->
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,
      price2: "",

      required,
      email,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama Blok",
          field: "name",
          thClass: "text-center",
        },
        {
          label: "Nama Kavling",
          field: "kavling_name",
          thClass: "text-center",
        },
        {
          label: "Status",
          field: "payment_status",
          thClass: "text-center",
        },
        {
          label: "Pembeli",
          field: "buyer_name",
          thClass: "text-center",
        },
        {
          label: "Luas (m2)",
          field: "length",
          thClass: "text-center",
        },
        {
          label: "Harga",
          field: "price",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      formDisabled: {
        purchase_date: "",
        buyer_name: "",
        facility: "",
        payment_status: "",
        kavling_name: "",
      },

      formSubmit: {
        name: "",
        kavling_id: "",
        price: "",
        length: "",
        width: "",
        is_booked: false,
        note: "",
        facility: "",
      },

      kavlingOptions: [],
      paymentStatusBlockOptions: [],

      // old delete
      searchTerm: "",

      name: "",
      kavling_id: "",
      payment_status: "",
      buyer_name: "",
      facility: "",

      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_kavling: "",

      nama: "",

      selected: null,
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getKavling();
    this.getPaymenStatusBlock();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {},

  // watch: {
  //   value: {
  //     handler(after) {
  //       this.formSubmit.price = this.format2(after);
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.name) {
        search0 = "&name=" + this.name;
      } else {
        search0;
      }

      let search1 = "";
      if (this.kavling_id) {
        search1 = "&kavling_id=" + this.kavling_id;
      } else {
        search1;
      }

      let search2 = "";
      if (this.payment_status) {
        search2 = "&payment_status=" + this.payment_status;
      } else {
        search2;
      }

      let search3 = "";
      if (this.buyer_name) {
        search3 = "&buyer_name=" + this.buyer_name;
      } else {
        search3;
      }

      let search4 = "";
      if (this.facility) {
        search4 = "&facility=" + this.facility;
      } else {
        search4;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/block/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=name&sort_dir=1" +
              search0 +
              search1 +
              search2 +
              search3 +
              search4
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalTambah() {
      this.formSubmit.name = "";
      this.formSubmit.kavling_id = "";
      this.formSubmit.price = "";
      this.formSubmit.length = "";
      this.formSubmit.width = "";
      this.formSubmit.is_booked = false;
      this.formSubmit.note = "";
      this.formSubmit.facility = "";
      this.price2 = "";

      this.$refs["ref-form-data-blok"].show();
      this.aksi = "tambahData";
    },

    showModalDetail(props) {
      return new Promise(() => {
        axios
          .get("v1/block/" + props.row._id)
          .then((res) => {
            if (
              res.data.purchase_date === null ||
              res.data.purchase_date === ""
            ) {
              this.formDisabled.purchase_date = "";
            } else {
              this.formDisabled.purchase_date =
                res.data.purchase_date.substring(0, 10) +
                " " +
                res.data.purchase_date.substring(11, 19);
            }
            this.formDisabled.buyer_name = res.data.buyer_name;
            this.formDisabled.facility = res.data.facility;
            this.formDisabled.payment_status = res.data.payment_status;
            this.formDisabled.kavling_name = res.data.kavling_name;

            this.formSubmit.name = res.data.name;
            this.formSubmit.kavling_id = res.data.kavling_id;
            this.formSubmit.price = res.data.price;
            this.formSubmit.length = res.data.length;
            this.formSubmit.width = res.data.width;
            this.formSubmit.is_booked = res.data.is_booked;
            this.formSubmit.note = res.data.note;

            this.$refs["ref-detail-data-blok"].show();
            this.aksi = "detailData";

            this.handleInputHarga();

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get("v1/block/" + props.row._id)
          .then((res) => {
            if (
              res.data.purchase_date === null ||
              res.data.purchase_date === ""
            ) {
              this.formDisabled.purchase_date = "";
            } else {
              this.formDisabled.purchase_date =
                res.data.purchase_date.substring(0, 10) +
                " " +
                res.data.purchase_date.substring(11, 19);
            }

            this.id_blok = res.data._id;

            this.formDisabled.buyer_name = res.data.buyer_name;
            this.formDisabled.facility = res.data.facility;
            this.formDisabled.payment_status = res.data.payment_status;
            this.formDisabled.kavling_name = res.data.kavling_name;

            this.formSubmit.name = res.data.name;
            this.formSubmit.kavling_id = res.data.kavling_id;
            this.formSubmit.price = res.data.price;

            this.price2 = res.data.price;

            this.formSubmit.length = res.data.length;
            this.formSubmit.width = res.data.width;
            this.formSubmit.is_booked = res.data.is_booked;
            this.formSubmit.note = res.data.note;

            this.$refs["ref-form-data-blok"].show();
            this.aksi = "ubahData";

            this.handleInputHarga();

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    kembaliModalForm() {
      this.$refs["ref-form-data-blok"].hide();
      this.show = false;
    },

    kembaliModalDetail() {
      this.$refs["ref-detail-data-blok"].hide();
      this.show = false;
    },

    submitForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.aksi === "tambahData") {
            return new Promise(() => {
              axios
                .post("v1/block", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Blok Berhasil`,
                    },
                  });
                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-form-data-blok"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/block/" + this.id_blok, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Blok Berhasil`,
                    },
                  });
                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-form-data-blok"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          }
        }
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Peringatan",
        text: "Menghapus blok akan menghapus data pembelian!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/block/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Blok Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getKavling() {
      return new Promise(() => {
        axios.get("v1/kavling/combo").then((res) => {
          this.kavlingOptions = res.data;
        });
      });
    },

    getPaymenStatusBlock() {
      return new Promise(() => {
        axios.get("v1/block/combo/payment_status").then((res) => {
          this.paymentStatusBlockOptions = res.data;
        });
      });
    },

    pilihKavling(event) {
      this.formSubmit.kavling_id = event;
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.price);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    resetFilter() {
      this.name = "";
      this.kavling_id = "";
      this.payment_status = "";
      this.buyer_name = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
